import React, { useState } from "react";
import { SocialLinks_API } from "../../../Utilities/APIs";
import Form from "../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import NumberInput from "../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";

function CreateViewSocialLinks({
  setShowCreateForm,
  setShowModal,
  triggerFetch,
}) {
  const [name, setName] = useState("");
  const [precedence, setPrecedence] = useState("");
  const [link, setLink] = useState("");
  const axiosInstance = useAxiosInstance();

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();

    itemData.append("name", name);
    itemData.append("precedence", precedence);
    itemData.append("link", link);

    const response = await axiosInstance.post(SocialLinks_API, itemData);

    console.log("SocialLinks321", response);

    if (response) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Name`}
        value={name}
        placeholder={`Enter Name`}
        setState={setName}
      />
      <NumberInput
        label={`precedence`}
        value={precedence}
        placeholder={`Enter precedence`}
        setState={setPrecedence}
      />

      <ShortTextInput
        label={`Link`}
        value={link}
        placeholder={`Enter Link`}
        setState={setLink}
      />
      <FormSubmitButton text="Create" />
    </Form>
  );
}

export default CreateViewSocialLinks;
