import React, { useEffect, useState } from "react";
import Form from "../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import { Gallery_Single_Item_API, GalleryItem_API, NEWS_API } from "./../../../../Utilities/APIs";
import CustomEditor from "../../../Partials/Elements/CustomEditor/CustomEditor";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";

function UpdateGalleryItem({
  targetID,
  triggerFetch,
  setShowUpdateForm,
  setShowModal,
}) {
  const [image, setImage] = useState("");
  const [precedence, setPrecedence] = useState(0);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetProductInfo() {
      const { data } = await axiosInstance.get(Gallery_Single_Item_API + targetID
      );
      console.log(data.precedence);
      setPrecedence(data.precedence);
      setImage(data.image);
    }
    fetchAndSetProductInfo();
  }, [targetID, axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();

    itemData.append("image", image);
    itemData.append("precedence", precedence);

    const response = await axiosInstance.patch(
      Gallery_Single_Item_API + targetID,
      itemData
    );

    if (response) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        fieldId="2"
        value={precedence}
        state={precedence}
        setState={setPrecedence}
        placeholder="precedence"
      />
      <ImageInput
        fieldId="1"
        state={image}
        setState={setImage}
        allowUpdateImage
      >
        Update Card Image
      </ImageInput>
      <FormSubmitButton text="Update" />
    </Form>
  );
}
export default UpdateGalleryItem;
