import React, { useEffect, useState } from 'react';
import Form from '../../Partials/Layouts/Forms/Form';
import ShortTextInput from '../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput';
import ImageInput from '../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput';
import FormSubmitButton from '../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton';

import { CATEGORIES_API, PRODUCTS_API } from '../../../Utilities/APIs';
import SelectInput from '../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput';
import SelectOption from '../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption';
import slugify from 'react-slugify';
import CustomEditor from '../../Partials/Elements/CustomEditor/CustomEditor';
import useAxiosInstance from '../../../Utilities/Hooks/useAxiosInstance';
import SwitchInput from '../../Partials/Elements/switch/switchinput';

function CreateProduct({ setShowCreateForm, setShowModal, triggerFetch }) {
  const [category, setCategory] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState('');
  const [pdf, setPdf] = useState('');
  const [productModel, setProductModel] = useState('');
  const [brand, setBrand] = useState('');
  const [isApplicable, setIsApplicable] = useState(false);
  const [catagories, setCatagories] = useState(null);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetCatagories() {
      const { data } = await axiosInstance.get(CATEGORIES_API);
      setCatagories(data);
    }
    fetchAndSetCatagories();
  }, [axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();

    itemData.append('category', category);
    itemData.append('name', name);
    itemData.append('slug', slugify(name));
    itemData.append('description', description);
    itemData.append('productModel', productModel);
    itemData.append('image', image);
    itemData.append('pdf', pdf);
    itemData.append('brand', brand);
    itemData.append('isNew', isApplicable);

    console.log('itemData', itemData);

    const response = await axiosInstance.post(PRODUCTS_API, itemData);

    if (response) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <SelectInput label={'Category'} value={category} setState={setCategory}>
        <SelectOption optionValue='' optionText='Select Category' />
        {catagories?.map((category) => (
          <SelectOption
            optionValue={category._id}
            key={category._id}
            optionText={category?.name}
          />
        ))}
      </SelectInput>
      <ShortTextInput
        label={`Name`}
        value={name}
        placeholder={`Enter Name`}
        setState={setName}
      />
      <ShortTextInput
        label={`Slug`}
        value={slugify(name)}
        placeholder={`Slug`}
        // setState={setSlug}
      />
      <ShortTextInput
        label={`Model`}
        value={productModel}
        placeholder={`Enter Model`}
        setState={setProductModel}
      />
      <ShortTextInput
        label={`Brand`}
        value={brand}
        placeholder={`Enter Brand`}
        setState={setBrand}
      />
      <SwitchInput
        label={'isNew'}
        toggleSwitch={() => setIsApplicable((prevState) => !prevState)}
        checked={isApplicable}
      />
      <CustomEditor setState={setDescription} />
      <ImageInput
        fieldId='1'
        state={image}
        setState={setImage}
        allowCreateImage
      >
        Upload product Image
      </ImageInput>
      <ImageInput fieldId='2' state={pdf} setState={setPdf} allowCreateImage>
        Upload product Pdf
      </ImageInput>
      <FormSubmitButton text='Create' />
    </Form>
  );
}

export default CreateProduct;
