import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom';

import './App.css';
import axios from 'axios';

import NavBar from './Partials/Sections/NavBar/NavBar';
import SideBar from './Partials/Sections/SideBar/SideBar';
import Login from './Screens/AuthScreens/Login/Login';
import Dashboard from './Screens/Dashboard/Dashboard';
import InviteEmployees from './Screens/AuthScreens/InviteEmployees/InviteEmployees';
import EmployeesScreen from './Screens/AuthScreens/EmployeesScreen';
import Employees from './Screens/AuthScreens/Employees/Employees';
import Register from './Screens/AuthScreens/Register/Register';
import RecoverPassOne from './Screens/AuthScreens/RecoverPassOne';
import RecoverPassThree from './Screens/AuthScreens/RecoverPassThree';

import UsersScreen from './Screens/UsersScreen/UsersScreen';

import Customers from './Screens/UsersScreen/Customers/Customers';
import EmployeeScreen from './Screens/AuthScreens/Employees/EmployeeScreen';

import Contacts from './Screens/Contacts/Contacts';
import Products from './Screens/Products/Products';
import ProductAsset from './Screens/ProductAsset/ProductAsset';
import Category from './Screens/Category/Category';
import AboutUs from './Screens/AboutScreen/AboutUs/AboutUs';
import AboutScreen from './Screens/AboutScreen/AboutScreen';
import Manager from './Screens/AboutScreen/Manager/Manager';
import OurTeam from './Screens/AboutScreen/OurTeam/OurTeam';
import Testimonial from './Screens/AboutScreen/Testimonial/Testimonial';
import CareerScreen from './Screens/CareerScreen/CareerScreen';
import WhyJoinMedipac from './Screens/CareerScreen/WhyJoinMedipac/WhyJoinMedipac';
import CareerOpenings from './Screens/CareerScreen/CareerOpenings/CareerOpenings ';
import News from './Screens/AboutScreen/News/News';
import Blogs from './Screens/AboutScreen/Blogs/Blogs';
import OurSolution from './Screens/Dashboard/OurSolution/OurSolution';
import Banner from './Screens/Dashboard/Banner/Banner';
import Supplier from './Screens/SupplierScreen/Supplier';
import Solution from './Screens/SolutionScreen/Solution';
import ResourcesScreen from './Screens/Resources/ResourcesScreen';
import CaseStudies from './Screens/Resources/CaseStudies/CaseStudies';
import Gallery from './Screens/Resources/Gallery/Gallery';
import GalleryItem from './Screens/Resources/GalleryItem/GalleryItem';
import SocialLinks from './Screens/SocialLinks/SocialLinks.js';
import NewsImage from './Screens/AboutScreen/News/NewsImages/NewsImages.js';
import AppContext from '../AppContext/AppContext.js';
import { useContext } from 'react';
import BlogImage from './Screens/AboutScreen/Blogs/BlogsImages/BlogsImages.js';
import CaseStudyImage from './Screens/Resources/CaseStudies/CaseStudyImages/CaseStudyImages.js';
import Newsletter from './Screens/Dashboard/Newsletter/Newsletter.js';
import Award from './Screens/AboutScreen/Award/Award.js';
import Settings from './Screens/Settings/Settings.js';
import CarrerApply from './Screens/CareerScreen/CareerOpenings/CareerApply/CarrerApply.js';
import ManageSection from './Screens/Dashboard/ManageSection/ManageSection.js';
import SolutionScreen from './Screens/SolutionScreen/SolutionScreen.js';
import GetInTouchBanner from './Screens/Dashboard/Get-In-Touch-Banner/GetInTouchBanner.js';
import Service from './Screens/ServiceScreen/Service.js';
import Partners from './Screens/SolutionScreen/Partners/Partners.js';
import OurClients from './Screens/Dashboard/OurClients/OurClients.js';

function App() {
  const { logout, employee } = useContext(AppContext);

  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    (err) => {
      if (err.response.status === 401) {
        logout();
      }
      return Promise.reject(err);
    },
  );

  return (
    <section>
      <BrowserRouter>
        <NavBar />
        <main>
          {employee && <SideBar />}
          <section className='screen-container'>
            <Routes>
              <Route
                path='/login'
                element={!employee ? <Login /> : <Navigate to={'/'} />}
              />
              <Route
                path='/register/:token'
                element={!employee ? <Register /> : <Navigate to={'/'} />}
              />
              <Route path='/recover-pass-one' element={<RecoverPassOne />} />
              <Route
                path='/resetEmployeePassword/:token'
                element={<RecoverPassThree />}
              />

              <Route
                path='/employeeScreens'
                element={
                  employee ? <EmployeesScreen /> : <Navigate to={'/login'} />
                }
              />
              <Route
                path='/employeeInvites'
                element={
                  employee ? <InviteEmployees /> : <Navigate to={'/login'} />
                }
              />
              <Route
                path='/employeeScreen'
                element={
                  employee ? <EmployeeScreen /> : <Navigate to={'/login'} />
                }
              />
              <Route
                path='/employees'
                element={employee ? <Employees /> : <Navigate to={'/login'} />}
              />
              <Route
                path='/customers'
                element={employee ? <Customers /> : <Navigate to={'/login'} />}
              />

              <Route
                path='/'
                element={employee ? <Dashboard /> : <Navigate to={'/login'} />}
              />

              <Route
                path='/formResponses'
                element={employee ? <Contacts /> : <Navigate to={'/login'} />}
              />
              <Route
                path='/usersScreen'
                element={
                  employee ? <UsersScreen /> : <Navigate to={'/login'} />
                }
              />

              <Route
                path='/ourSolution'
                element={
                  employee ? <OurSolution /> : <Navigate to={'/login'} />
                }
              />
              <Route
                path='/service'
                element={
                  employee ? <Service /> : <Navigate to={'/login'} />
                }
              />
              <Route
                path='/sections'
                element={
                  employee ? <ManageSection /> : <Navigate to={'/login'} />
                }
              />

              <Route
                path='/newsletter'
                element={employee ? <Newsletter /> : <Navigate to={'/login'} />}
              />

              <Route
                path='/products'
                element={employee ? <Products /> : <Navigate to={'/login'} />}
              />
              <Route
                path='/productAsset/:productId'
                element={
                  employee ? <ProductAsset /> : <Navigate to={'/login'} />
                }
              />
              <Route
                path='/carrerApply/:carreerId'
                element={
                  employee ? <CarrerApply /> : <Navigate to={'/login'} />
                }
              />
              <Route
                path='/category'
                element={employee ? <Category /> : <Navigate to={'/login'} />}
              />

              <Route
                path='/aboutScreen'
                element={
                  employee ? <AboutScreen /> : <Navigate to={'/login'} />
                }
              />
              <Route
                path='/aboutUs'
                element={employee ? <AboutUs /> : <Navigate to={'/login'} />}
              />
              <Route
                path='/manager'
                element={employee ? <Manager /> : <Navigate to={'/login'} />}
              />

              <Route
                path='/awards'
                element={employee ? <Award /> : <Navigate to={'/login'} />}
              />
              <Route
                path='/ourTeam'
                element={employee ? <OurTeam /> : <Navigate to={'/login'} />}
              />
              <Route
                path='/testimonial'
                element={
                  employee ? <Testimonial /> : <Navigate to={'/login'} />
                }
              />
              <Route
                path='/partners'
                element={
                  employee ? <Partners /> : <Navigate to={'/login'} />
                }
              />

              <Route
                path='/careerScreen'
                element={
                  employee ? <CareerScreen /> : <Navigate to={'/login'} />
                }
              />
              <Route
                path='/why-join-medipac'
                element={
                  employee ? <WhyJoinMedipac /> : <Navigate to={'/login'} />
                }
              />
              <Route
                path='/career-openings'
                element={
                  employee ? <CareerOpenings /> : <Navigate to={'/login'} />
                }
              />

              <Route
                path='/news'
                element={employee ? <News /> : <Navigate to={'/login'} />}
              />
              <Route
                path='/news/:id'
                element={employee ? <NewsImage /> : <Navigate to={'/login'} />}
              />
              <Route
                path='/blogs'
                element={employee ? <Blogs /> : <Navigate to={'/login'} />}
              />

              <Route
                path='/blogs/:id'
                element={employee ? <BlogImage /> : <Navigate to={'/login'} />}
              />

              <Route
                path='/banner'
                element={employee ? <Banner /> : <Navigate to={'/login'} />}
              />
              <Route
                path='/getintouchbanner'
                element={employee ? <GetInTouchBanner /> : <Navigate to={'/login'} />}
              />
              <Route
                path='/ourclients'
                element={employee ? <OurClients /> : <Navigate to={'/login'} />}
              />
              <Route
                path='/supplier'
                element={employee ? <Supplier /> : <Navigate to={'/login'} />}
              />
              <Route
                path='/solutionscreen'
                element={employee ? <SolutionScreen /> : <Navigate to={'/login'} />}
              />
              <Route
                path='/solutions'
                element={employee ? <Solution /> : <Navigate to={'/login'} />}
              />

              {/* SocialLinks routes */}
              <Route
                path='/socialLinks'
                element={
                  employee ? <SocialLinks /> : <Navigate to={'/login'} />
                }
              />
              {/* Show Section route */}
              <Route
                path='/showSections'
                element={
                  employee ? <ManageSection /> : <Navigate to={'/login'} />
                }
              />

              {/* resources page section routes */}
              <Route
                path='/resources_screen'
                element={
                  employee ? <ResourcesScreen /> : <Navigate to={'/login'} />
                }
              />
              <Route
                path='/caseStudies'
                element={
                  employee ? <CaseStudies /> : <Navigate to={'/login'} />
                }
              />

              <Route
                path='/caseStudies/:id'
                element={
                  employee ? <CaseStudyImage /> : <Navigate to={'/login'} />
                }
              />
              <Route
                path='/gallery'
                element={employee ? <Gallery /> : <Navigate to={'/login'} />}
              />
              <Route
                path='/gallery/:galleryId'
                element={
                  employee ? <GalleryItem /> : <Navigate to={'/login'} />
                }
              />
              <Route
                path='/settings/'
                element={employee ? <Settings /> : <Navigate to={'/login'} />}
              />
            </Routes>
          </section>
        </main>
      </BrowserRouter>
    </section>
  );
}

export default App;
