import React, { useEffect, useState } from "react";
import { MANAGER_API } from "../../../../Utilities/APIs";

import Form from "../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";

function UpdateManager({
  targetID,
  triggerFetch,
  setShowUpdateForm,
  setShowModal,
}) {
  const [name, setName] = useState("");
  const [designation, setDesignation] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const axiosInstance = useAxiosInstance();
  useEffect(() => {
    async function fetchAndSetProductInfo() {
      const { data } = await axiosInstance.get(MANAGER_API + targetID);
      setName(data.name);
      setDesignation(data.designation);
      setDescription(data.description);
      setImage(data.image);
    }
    fetchAndSetProductInfo();
  }, [targetID]);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();
    itemData.append("name", name);
    itemData.append("designation", designation);
    itemData.append("description", description);
    itemData.append("image", image);

    const response = await axiosInstance.patch(
      MANAGER_API + targetID,
      itemData
    );

    if (response) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Name`}
        value={name}
        placeholder={`Enter Name`}
        setState={setName}
      />
      <ShortTextInput
        label={`Designation`}
        value={designation}
        placeholder={`Enter Designation`}
        setState={setDesignation}
      />
      <ShortTextInput
        label={`Description`}
        value={description}
        placeholder={`Enter Description`}
        setState={setDescription}
      />
      <ImageInput
        fieldId="1"
        state={image}
        setState={setImage}
        allowUpdateImage
      >
        Upload About Image1
      </ImageInput>

      {/* <FormSubmitButton text="Update Manager Info" /> */}
      <FormSubmitButton text="Update" />
    </Form>
  );
}

export default UpdateManager;
