import React from "react";
import { useState } from "react";
import Form from "../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";

import { ABOUT_US_API } from "../../../../Utilities/APIs";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";

function CreateAboutUs({ setShowCreateForm, setShowModal, triggerFetch }) {
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image1, setImage1] = useState("");
  const [image2, setImage2] = useState("");
  const axiosInstance = useAxiosInstance();

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();

    itemData.append("name", name);
    itemData.append("title", title);
    itemData.append("description", description);
    itemData.append("image1", image1);
    itemData.append("image2", image2);

    const response = await axiosInstance.post(ABOUT_US_API, itemData);

    if (response) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Name`}
        value={name}
        placeholder={`Enter Name`}
        setState={setName}
      />
      <ShortTextInput
        label={`Title`}
        value={title}
        placeholder={`Enter Title`}
        setState={setTitle}
      />
      <ShortTextInput
        label={`Description`}
        value={description}
        placeholder={`Enter Description`}
        setState={setDescription}
      />
      <ImageInput
        fieldId="1"
        state={image1}
        setState={setImage1}
        allowCreateImage
      >
        Upload About Image1
      </ImageInput>
      <ImageInput
        fieldId="2"
        state={image2}
        setState={setImage2}
        allowCreateImage
      >
        Upload About Image2
      </ImageInput>
      {/* <FormSubmitButton text="Create About" /> */}
      <FormSubmitButton text="Create" />
    </Form>
  );
}

export default CreateAboutUs;
