import React, { useState } from "react";
import { OURCLIENTS_API } from "../../../../Utilities/APIs";
import Form from "../../../Partials/Layouts/Forms/Form";
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";

function CreateOurClient({ setShowCreateForm, setShowModal, triggerFetch }) {
  // const [name, setName] = useState("");
  // const [title, setTitle] = useState("");
  // const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  // const [video, setVideo] = useState("");

  const axiosInstance = useAxiosInstance();

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();

    // itemData.append("name", name);
    // itemData.append("title", title);
    // itemData.append("description", description);
    // itemData.append("video", video);
    itemData.append("image", image);

    const response = await axiosInstance.post(OURCLIENTS_API, itemData);

    if (response) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
   
      <ImageInput
        fieldId="1"
        state={image}
        setState={setImage}
        allowCreateImage
      >
        Upload 
      </ImageInput>
      <FormSubmitButton text="Create" />
    </Form>
  );
}

export default CreateOurClient;
