import React, { useState } from "react";
import Form from "../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import ImageInput from "../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import { ServicePage_API } from "../../../Utilities/APIs";
import CustomEditor from "../../Partials/Elements/CustomEditor/CustomEditor";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";

function CreateService({ setShowCreateForm, setShowModal, triggerFetch }) {
  const [serviceName, setServiceName] = useState("");
  const [detail, setDetail] = useState("");
  const [image, setImage] = useState(null);
  const axiosInstance = useAxiosInstance();

  async function handleSubmit(e) {
    e.preventDefault();

    const serviceData = new FormData();
    serviceData.append("serviceName", serviceName);
    serviceData.append("detail", detail);
    serviceData.append("image", image);

    const response = await axiosInstance.post(ServicePage_API, serviceData);

    if (response) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label="Service Name"
        value={serviceName}
        placeholder="Enter Service Name"
        setState={setServiceName}
      />
      <CustomEditor setState={setDetail} data={detail} />
      <ImageInput
        fieldId="1"
        state={image}
        setState={setImage}
        allowCreateImage
      >
        Upload Service Image
      </ImageInput>
      <FormSubmitButton text="Create" />
    </Form>
  );
}

export default CreateService;
