import React, { useEffect, useState } from "react";
import Form from "../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import ImageInput from "../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";

import { ServicePage_API } from "../../../Utilities/APIs";
import CustomEditor from "../../Partials/Elements/CustomEditor/CustomEditor";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";

function UpdateService({
  setShowModal,
  setShowUpdateForm,
  targetID,
  triggerFetch,
}) {
  const [serviceName, setServiceName] = useState("");
  const [detail, setDetail] = useState("");
  const [image, setImage] = useState(null);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetServiceInfo() {
      const { data } = await axiosInstance.get(ServicePage_API + targetID);
      setServiceName(data.serviceName);
      setDetail(data.detail);
      setImage(data.image);
    }
    fetchAndSetServiceInfo();
  }, [axiosInstance, targetID]);

  async function handleSubmit(e) {
    e.preventDefault();

    const serviceData = new FormData();
    serviceData.append("serviceName", serviceName);
    serviceData.append("detail", detail);
    serviceData.append("image", image);

    const response = await axiosInstance.patch(
      ServicePage_API + targetID,
      serviceData
    );

    if (response) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label="Service Name"
        value={serviceName}
        placeholder="Enter Service Name"
        setState={setServiceName}
      />
      <CustomEditor setState={setDetail} data={detail} />
      <ImageInput
        fieldId="1"
        state={image}
        setState={setImage}
        allowUpdateImage={true}
      >
        Upload Service Image
      </ImageInput>
      <FormSubmitButton text="Update" />
    </Form>
  );
}

export default UpdateService;
