import React, { useEffect, useState } from "react";

import { PRODUCTS_ASSET_API } from "../../../Utilities/APIs";
import CreateButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDashboardBoilerplate/CRUDHeader/CreateButton/CreateButton";
import CRUDTable from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ViewButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import EditButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/EditButton/EditButton";
import DeleteButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/DeleteButton/DeleteButton";
import Modal from "../../Partials/Elements/Modal/Modal";
import CRUDDeleteBoilerPlate from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDDeleteBoilerPlate/CRUDDeleteBoilerPlate";
import CreateProductAsset from "./CreateProductAsset";
import { useParams } from "react-router-dom";
import ViewProductAsset from "./ViewProductAsset";
import UpdateProductAsset from "./UpdateProductAsset";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";

function ProductAsset() {
  const [productAsset, setProductAsset] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const axiosInstance = useAxiosInstance();

  const { productId } = useParams();

  useEffect(() => {
    async function fetchAndSetProducts() {
      const { data } = await axiosInstance.get(
        PRODUCTS_ASSET_API + "getProductAssetByProduct/" + productId
      );
      setProductAsset(data);
    }
    fetchAndSetProducts();
  }, [toggleFetch, productId]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  const renderMedia = (src) => {
    const isVideo = src.endsWith(".mp4");
    if (isVideo) {
      return (
        <video
          muted
          autoPlay
          loop
          style={{
            width: "100px",
            height: "100px",
            objectFit: "cover",
          }}
        >
          <source src={process.env.REACT_APP_SPACES_URL + src} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    }
    return (
      <img
        src={process.env.REACT_APP_SPACES_URL + src}
        alt=""
        style={{
          width: "100px",
          height: "100px",
          objectFit: "cover",
        }}
      />
    );
  };

  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <h1 className="heading">{`Product Assets (${productAsset?.length} in total)`}</h1>
          <CreateButton
            screenTopicSingular="Product Asset"
            setShowModal={setShowModal}
            setShowCreateForm={setShowCreateForm}
          />
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Media" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {productAsset?.map((product) => (
              <CRUDTableRow key={product._id}>
                <td>{renderMedia(product.image)}</td>
                <td className="action_button_cell">
                  <ViewButton
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={product._id}
                    setTargetID={setTargetID}
                  />
                  <EditButton
                    setShowModal={setShowModal}
                    setShowUpdateForm={setShowUpdateForm}
                    targetID={product._id}
                    setTargetID={setTargetID}
                  />
                  <DeleteButton
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowDeleteSection}
                    targetID={product._id}
                    setTargetID={setTargetID}
                  />
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>
      {showModal && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Product`) ||
            (showUpdateForm && `Update Product `) ||
            (showDeleteSection && `Delete Product`)
          }
        >
          {showCreateForm && (
            <CreateProductAsset
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
              targetID={targetID}
              productId={productId}
            />
          )}
          {showUpdateForm && (
            <UpdateProductAsset
              targetID={targetID}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
              productId={productId}
            />
          )}

          {showDeleteSection && (
            <CRUDDeleteBoilerPlate
              api={PRODUCTS_ASSET_API}
              targetID={targetID}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              hasName
              triggerFetch={triggerFetch}
            />
          )}
        </Modal>
      )}

      {showModal && showViewSection && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showViewSection && `View Product Asset`}
        >
          <ViewProductAsset targetID={targetID} />
        </Modal>
      )}
    </>
  );
}

export default ProductAsset;
