import React, { useEffect, useState } from "react";
import { CATEGORIES_API } from "../../../Utilities/APIs";

import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";

function ViewCategory({ targetID }) {
  const [categoryInfo, setCategoryInfo] = useState(null);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetCategoryInfo() {
      const { data } = await axiosInstance.get(CATEGORIES_API + targetID);
      setCategoryInfo(data);
    }
    fetchAndSetCategoryInfo();
  }, [targetID]);

  return (
    <div className="crud_view_content">
      {categoryInfo && (
        <>
          <h1>Category Name</h1>
          <p>{categoryInfo.name}</p>

          <h1>precedence</h1>
          <p>{categoryInfo.precedence}</p>
        </>
      )}
    </div>
  );
}

export default ViewCategory;
