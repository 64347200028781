import React, { useEffect, useState } from "react";
import { CATEGORIES_API, PRODUCTS_API } from "../../../Utilities/APIs";
import Form from "../../Partials/Layouts/Forms/Form";
import SelectInput from "../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";
import ShortTextInput from "../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import ImageInput from "../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import slugify from "react-slugify";
import CustomEditor from "../../Partials/Elements/CustomEditor/CustomEditor";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";

function UpdateProduct({
  targetID,
  triggerFetch,
  setShowUpdateForm,
  setShowModal,
}) {
  const [category, setCategory] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [pdf, setPdf] = useState("");
  const [productModel, setProductModel] = useState("");
  const [brand, setBrand] = useState("");

  const [catagories, setCatagories] = useState(null);
  const [productInfo, setProductInfo] = useState(null);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetCatagories() {
      const { data } = await axiosInstance.get(CATEGORIES_API);
      setCatagories(data);
    }
    fetchAndSetCatagories();

    async function fetchAndSetProductInfo() {
      const { data } = await axiosInstance.get(PRODUCTS_API + targetID);
      setName(data.name);
      setCategory(data.category?._id);
      setDescription(data.description);
      setImage(data.image);
      setPdf(data.pdf);
      setProductModel(data.productModel);
      setBrand(data.brand);
    }
    fetchAndSetProductInfo();
  }, [targetID, productInfo]);

  useEffect(() => {
    async function fetchAndSetProductInfo() {
      const { data } = await axiosInstance.get(PRODUCTS_API + targetID);
      setProductInfo(data);
    }
    fetchAndSetProductInfo();
  }, [targetID]);

  async function handleSubmit(e) {
    e.preventDefault();
    const itemData = new FormData();

    itemData.append("category", category);
    itemData.append("name", name);
    itemData.append("slug", slugify(name));
    itemData.append("productModel", productModel);
    itemData.append("description", description);
    itemData.append("image", image);
    itemData.append("pdf", pdf);
    itemData.append("brand", brand);

    const response = await axiosInstance.patch(
      PRODUCTS_API + targetID,
      itemData
    );

    if (response) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }
  return (
    <Form onSubmit={handleSubmit} hasImage>
      <SelectInput label={"Category"} value={category} setState={setCategory}>
        <SelectOption optionValue="" optionText="Select Category" />
        {catagories?.map((category) => (
          <SelectOption
            optionValue={category._id}
            key={category._id}
            optionText={category?.name}
          />
        ))}
      </SelectInput>
      <ShortTextInput
        label={`Name`}
        value={name}
        placeholder={`Enter Name`}
        setState={setName}
      />
      <ShortTextInput
        label={`Slug`}
        value={slugify(name)}
        placeholder={`Slug`}
        // setState={setSlug}
      />
      <ShortTextInput
        label={`Model`}
        value={productModel}
        placeholder={`Enter Model`}
        setState={setProductModel}
      />
      <ShortTextInput
        label={`Brand`}
        value={brand}
        placeholder={`Enter Brand`}
        setState={setBrand}
      />
      <CustomEditor setState={setDescription} data={description} />
      <ImageInput
        fieldId="1"
        state={image}
        setState={setImage}
        allowUpdateImage
      >
        Upload product Image
      </ImageInput>

      <ImageInput fieldId="2" state={pdf} setState={setPdf} allowUpdateImage>
        Upload product Pdf
      </ImageInput>
      <FormSubmitButton text="Update" />
    </Form>
  );
}

export default UpdateProduct;
