import React from "react";
import NavCardList from "../../Partials/Layouts/NavCardList/NavCardList";
import NavCard from "../../Partials/Layouts/NavCardList/NavCard/NavCard";
import { RiImageLine } from "react-icons/ri";
import { AiOutlineSolution,AiOutlineAppstore  } from "react-icons/ai";
import { MdOutlineCategory, MdOutlineMessage } from "react-icons/md";
import { MdDynamicFeed } from "react-icons/md";

function Dashboard() {
  return (
    <NavCardList numOfCards={"eight"}>
      <NavCard cardName={"Home-Banner"} navCardLink={"/banner"}>
        <RiImageLine />
      </NavCard>
      <NavCard cardName={"OurSolution"} navCardLink={"/ourSolution"}>
        <AiOutlineSolution />
      </NavCard>
      <NavCard cardName={"Newsletter"} navCardLink={"/newsletter"}>
        <MdOutlineMessage />
      </NavCard>
      <NavCard cardName={"Manage Sections"} navCardLink={"/showSections"}>
      <AiOutlineAppstore />

      </NavCard>
      <NavCard cardName={"Manage Categories"} navCardLink={"/category"}>
      <MdOutlineCategory />
      </NavCard>
      <NavCard cardName={"Get-In-Touch-Banner"} navCardLink={"/getintouchbanner"}>
        <RiImageLine />
      </NavCard>
      <NavCard cardName={"Our-Clients"} navCardLink={"/ourclients"}>
        <RiImageLine />
      </NavCard>
    </NavCardList>
  );
}

export default Dashboard;
