import React, { useState } from "react";
import { NewsImage_API } from "../../../../../../Utilities/APIs";
import Form from "../../../../../Partials/Layouts/Forms/Form";
import ImageInput from "../../../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
import VideoInput from "../../../../../Partials/Layouts/Forms/FormInputs/VideoInput/VideoInput";

function CreateNewsImage({
  setShowCreateForm,
  setShowModal,
  triggerFetch,
  newsId,
}) {
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const axiosInstance = useAxiosInstance();

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();

    itemData.append("image", image);
    if(video) itemData.append("video", video);
    itemData.append("newsId", newsId);

    const response = await axiosInstance.post(NewsImage_API, itemData);

    if (response) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ImageInput
        fieldId="1"
        state={image}
        setState={setImage}
        allowCreateImage={true}
      >
        Upload Card Image
      </ImageInput>
      <VideoInput fieldId="2" state={video} setState={setVideo} />
      <FormSubmitButton text="Create" />
    </Form>
  );
}
export default CreateNewsImage;
