import React, { useEffect, useState } from "react";
import { PARTNER_API } from "../../../../Utilities/APIs";

import Image from "../../../Partials/Elements/Image/Image";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";

function ViewPartner({ targetID }) {
  const [partnerInfo, setPartnerInfo] = useState(null);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetPartnerInfo() {
      const { data } = await axiosInstance.get(PARTNER_API + targetID);
      setPartnerInfo(data);
    }
    fetchAndSetPartnerInfo();
  }, [targetID, axiosInstance]);

  return (
    <div className="crud_view_content">
      {partnerInfo && (
        <>
          <h1>Name</h1>
          <p>{partnerInfo.name}</p>

          <h1>Precedence</h1>
          <p>{partnerInfo.precedence}</p>

          <h1>Image</h1>
          <Image imgLink={partnerInfo.image} imgAlt="Partner Image" />
        </>
      )}
    </div>
  );
}

export default ViewPartner;
