import React, { useEffect, useState } from "react";
import { ABOUT_US_API } from "../../../../Utilities/APIs";

import Form from "../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";

function UpdateAboutUs({
  targetID,
  triggerFetch,
  setShowUpdateForm,
  setShowModal,
}) {
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image1, setImage1] = useState("");
  const [image2, setImage2] = useState("");

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetProductInfo() {
      const { data } = await axiosInstance.get(ABOUT_US_API + targetID);
      setName(data.name);
      setTitle(data.title);
      setDescription(data.description);
      setImage1(data.image1);
      setImage2(data.image2);
    }
    fetchAndSetProductInfo();
  }, [targetID]);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();
    itemData.append("name", name);
    itemData.append("title", title);
    itemData.append("description", description);
    itemData.append("image1", image1);
    itemData.append("image2", image2);

    const response = await axiosInstance.patch(
      ABOUT_US_API + targetID,
      itemData
    );

    if (response) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Name`}
        value={name}
        placeholder={`Enter Name`}
        setState={setName}
      />
      <ShortTextInput
        label={`Title`}
        value={title}
        placeholder={`Enter Title`}
        setState={setTitle}
      />
      <ShortTextInput
        label={`Description`}
        value={description}
        placeholder={`Enter Description`}
        setState={setDescription}
      />
      <ImageInput
        fieldId="1"
        state={image1}
        setState={setImage1}
        allowUpdateImage
      >
        Upload About Image1
      </ImageInput>
      <ImageInput
        fieldId="2"
        state={image2}
        setState={setImage2}
        allowUpdateImage
      >
        Upload About Image2
      </ImageInput>
      {/* <FormSubmitButton text="Update AboutUs" /> */}
      <FormSubmitButton text="Update" />
    </Form>
  );
}
export default UpdateAboutUs;
