import React, { useState } from "react";
import { PARTNER_API } from "../../../../Utilities/APIs";
import Form from "../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";

function CreatePartner({ setShowCreateForm, setShowModal, triggerFetch }) {
  const [image, setImage] = useState("");
  const [name, setName] = useState("");
  const [precedence, setPrecedence] = useState("");
  const axiosInstance = useAxiosInstance();

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();
    itemData.append("image", image);
    itemData.append("name", name);
    itemData.append("precedence", precedence);

    const response = await axiosInstance.post(PARTNER_API, itemData);

    if (response) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label="Name"
        value={name}
        placeholder="Enter Name"
        setState={setName}
      />
      <ShortTextInput
        label="Precedence"
        value={precedence}
        placeholder="Enter Precedence"
        setState={setPrecedence}
      />
      <ImageInput
        fieldId="1"
        state={image}
        setState={setImage}
        allowCreateImage
      >
        Upload Partner Logo
      </ImageInput>
      <FormSubmitButton text="Create" />
    </Form>
  );
}

export default CreatePartner;
