import React, { useEffect, useState } from "react";
import { PRODUCTS_ASSET_API } from "../../../Utilities/APIs";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";

function ViewProductAsset({ targetID }) {
  const [productAssetInfo, setProductAssetInfo] = useState(null);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetProductInfo() {
      const { data } = await axiosInstance.get(PRODUCTS_ASSET_API + targetID);
      setProductAssetInfo(data);
    }
    fetchAndSetProductInfo();
  }, [targetID]);

  const renderMedia = (src) => {
    const isVideo = src.endsWith(".mp4");
    if (isVideo) {
      return (
        <video
          muted
          autoPlay
          loop
          style={{
            width: "100%",
            height: "auto",
            objectFit: "cover",
          }}
        >
          <source src={process.env.REACT_APP_SPACES_URL + src} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    }
    return (
      <img
        src={process.env.REACT_APP_SPACES_URL + src}
        alt="Product Asset"
        style={{
          width: "100%",
          height: "auto",
          objectFit: "cover",
        }}
      />
    );
  };

  return (
    <div className="crud_view_content">
      {productAssetInfo && (
        <>
          <h1>Precedence</h1>
          <p>{productAssetInfo.precedence}</p>
          <h1>Media</h1>
          {renderMedia(productAssetInfo.image)}
        </>
      )}
    </div>
  );
}

export default ViewProductAsset;
