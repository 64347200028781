import React, { useEffect, useState } from "react";
import {

  ShowSections_API,
} from "../../../../Utilities/APIs";
import Form from "../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import SelectInput from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";

function UpdateSection({
  targetID,
  triggerFetch,
  setShowUpdateForm,
  setShowModal,
}) {
  const [sectionName, setSectionName] = useState("");
  const [isVisible, setIsVisible] = useState(true);

  const axiosInstance = useAxiosInstance();



  useEffect(() => {
    async function fetchAndSetTestimonialInfo() {
      const { data } = await axiosInstance.get(ShowSections_API + targetID);
      setSectionName(data.sectionName);
      setIsVisible(data.isVisible);
    }
    fetchAndSetTestimonialInfo();
  }, [targetID, axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();

    itemData.append("sectionName", sectionName);
    itemData.append("isVisible", isVisible);

    const response = await axiosInstance.patch(
      ShowSections_API + targetID,
      itemData
    );

    if (response) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <SelectInput label={"Visibility"} value={isVisible} setState={setIsVisible}>
        <SelectOption optionValue="" optionText="Select Visibility" />
        <SelectOption optionValue={true} key={1} optionText={"Yes"} />
        <SelectOption optionValue={false} key={2} optionText={"No"} />
      </SelectInput>
      <ShortTextInput
        label={`Section Name`}
        value={sectionName}
        placeholder={`Enter Section Name`}
        setState={setSectionName}
      />

      <FormSubmitButton text="Update" />
    </Form>
  );
}

export default UpdateSection;
