import { useState } from "react";
import { BLOGS_API } from "../../../../Utilities/APIs";
import Form from "../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import CustomEditor from "../../../Partials/Elements/CustomEditor/CustomEditor";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";

function CreatBlogs({ setShowCreateForm, setShowModal, triggerFetch }) {
  const [image, setImage] = useState("");
  const [title, setTitle] = useState("");
  const [time, setTime] = useState("");
  const [description, setDescription] = useState("");
  const [cardDescription, setCardDescription] = useState("");
  const axiosInstance = useAxiosInstance();

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();

    itemData.append("image", image);
    itemData.append("title", title);
    itemData.append("description", description);
    itemData.append("time", time);

    const response = await axiosInstance.post(BLOGS_API, itemData);

    if (response) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Title`}
        value={title}
        placeholder={`Enter Title`}
        setState={setTitle}
      />

      <ShortTextInput
        label={`Time`}
        value={time}
        placeholder={`Enter Time`}
        setState={setTime}
      />

      <ShortTextInput
        label={`Card Description`}
        value={cardDescription}
        placeholder={`Enter Time`}
        setState={setCardDescription}
      />

      <CustomEditor setState={setDescription} data={description} />
      <ImageInput
        fieldId="1"
        state={image}
        setState={setImage}
        allowCreateImage
      >
        Upload Image
      </ImageInput>
      {/* <FormSubmitButton text="Create Our Team" /> */}
      <FormSubmitButton text="Create" />
    </Form>
  );
}
export default CreatBlogs;
