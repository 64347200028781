import React, { useEffect, useState } from "react";
import Form from "../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import ImageInput from "../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";

import { SolutionPage_API } from "../../../Utilities/APIs";
import CustomEditor from "../../Partials/Elements/CustomEditor/CustomEditor";
import AddButton from "../../Partials/Elements/AddButton/AddButton";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";

function UpdateSolution({
  setShowModal,
  setShowUpdateForm,
  targetID,
  triggerFetch,
}) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [list, setList] = useState([]);
  const [listValue, setListValue] = useState("");
  const [image, setImage] = useState(null);
  const axiosInstance = useAxiosInstance();

  const addListItem = () => {
    setList([...list, listValue]);
    console.log(list.join(", ").split(","));
    setListValue("");
  };

  useEffect(() => {
    async function fetchAndSetSolutionInfo() {
      const { data } = await axiosInstance.get(SolutionPage_API + targetID);
      setTitle(data.title);
      setDescription(data.description);
      setList(data.list);
      setImage(data.image);
    }
    fetchAndSetSolutionInfo();
  }, [axiosInstance, targetID]);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();

    itemData.append("title", title);
    itemData.append("list", list.join(", "));
    itemData.append("description", description);
    itemData.append("image", image);

    const response = await axiosInstance.patch(
      SolutionPage_API + targetID,
      itemData
    );

    console.log("response12345", response);

    if (response) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Title`}
        value={title}
        placeholder={`Enter Title`}
        setState={setTitle}
      />
      <CustomEditor setState={setDescription} data={description} />

      <ul
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          gap: "25px",
          flexWrap: "wrap",
          marginTop: "15px",
          marginBottom: "15px",
          //   border: "1px solid rgba(255, 255, 255)",
        }}
      >
        {list.map((item, index) => (
          <li key={index}>
            {index + 1}.{item}
          </li>
        ))}
      </ul>

      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          width: "100%",
          justifyContent: "end",
          gap: "12px",
        }}
      >
        <ShortTextInput
          label={`List`}
          value={listValue}
          placeholder={`Enter Add a List`}
          setState={setListValue}
          groupStyle={{ width: "80%" }}
        />

        <AddButton addListItem={addListItem} />
      </div>
      <ImageInput
        fieldId="1"
        state={image}
        setState={setImage}
        allowUpdateImage={true}
      >
        Upload Solution Image
      </ImageInput>
      <FormSubmitButton text="Update" />
    </Form>
  );
}

export default UpdateSolution;
