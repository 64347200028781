import React, {  useState } from "react";
import {  OUR_SOLUTION_API, ShowSections_API } from "../../../../Utilities/APIs";

import Form from "../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import SelectInput from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../Partials/Layouts/Forms/FormInputs/SelectInput/SelectOption/SelectOption";

function CreateSection({ setShowCreateForm, setShowModal, triggerFetch }) {
  const [sectionName, setSectionName] = useState("");
  const [isVisible, setIsVisible] = useState(true);
  const axiosInstance = useAxiosInstance();




  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();

   

    itemData.append("sectionName", sectionName);
    itemData.append("isVisible", isVisible);
  

    const response = await axiosInstance.post(ShowSections_API, itemData);

    if (response) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Name`}
        value={sectionName}
        placeholder={`Enter Section Name`}
        setState={setSectionName}
      />

      <SelectInput label={"Visibility"} value={isVisible} setState={setIsVisible}>
        <SelectOption optionValue="" optionText="Select Visibility" />
     
          <SelectOption
            optionValue={true}
            key={1}
            optionText={"Yes"}
          />
          <SelectOption
            optionValue={false}
            key={2}
            optionText={"No"}
          />
      
      </SelectInput>
    
  
      <FormSubmitButton text="Create" />
    </Form>
  );
}

export default CreateSection;
