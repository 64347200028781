import React, { useEffect, useState } from "react";
import { Get_In_Touch_Banner_API, OURCLIENTS_API } from "../../../../Utilities/APIs";
import Form from "../../../Partials/Layouts/Forms/Form";
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";

function UpdateOurClient({
  targetID,
  triggerFetch,
  setShowUpdateForm,
  setShowModal,
}) {

  const [image, setImage] = useState("");
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetTestimonialInfo() {
      const { data } = await axiosInstance.get(
        OURCLIENTS_API + targetID
      );

      setImage(data.image);
    }
    fetchAndSetTestimonialInfo();
  }, [targetID]);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();


    itemData.append("image", image);

    const response = await axiosInstance.patch(
      Get_In_Touch_Banner_API + targetID,
      itemData
    );

    if (response) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ImageInput
        fieldId="1"
        state={image}
        setState={setImage}
        allowUpdateImage
      >
        Upload
      </ImageInput>
      <FormSubmitButton text="Update" />
    </Form>
  );
}

export default UpdateOurClient;
