import React, { useEffect, useState } from "react";
import { CAREER_OPENING_API } from "../../../../Utilities/APIs";
import Form from "../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import CustomEditor from "../../../Partials/Elements/CustomEditor/CustomEditor";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";

function UpdateCareerOpenings({
  targetID,
  triggerFetch,
  setShowUpdateForm,
  setShowModal,
}) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [cardDescription, setCardDescription] = useState("");
  const [salaryRange, setSalaryRange] = useState("");
  const [time, seTtime] = useState("");
  const [image, setImage] = useState("");
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetTestimonialInfo() {
      const { data } = await axiosInstance.get(CAREER_OPENING_API + targetID);
      setName(data.name);
      setDescription(data.description);
      setCardDescription(data.cardDescription);
      setSalaryRange(data.salaryRange);
      seTtime(data.time);
      setImage(data.image);
    }
    fetchAndSetTestimonialInfo();
  }, [targetID]);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();
    itemData.append("name", name);
    itemData.append("description", description);
    itemData.append("cardDescription", cardDescription);
    itemData.append("image", image);
    itemData.append("salaryRange", salaryRange);
    itemData.append("time", time);

    const response = await axiosInstance.patch(
      CAREER_OPENING_API + targetID,
      itemData
    );

    if (response) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Name`}
        value={name}
        placeholder={`Enter Name`}
        setState={setName}
      />
      <ShortTextInput
        label={`card Description`}
        value={cardDescription}
        placeholder={`Enter card Description`}
        setState={setCardDescription}
      />
      <CustomEditor setState={setDescription} data={description} />
      <ShortTextInput
        label={`SalaryRange`}
        value={salaryRange}
        placeholder={`Enter SalaryRange`}
        setState={setSalaryRange}
      />
      <ShortTextInput
        label={`Time`}
        value={time}
        placeholder={`Enter Time`}
        setState={seTtime}
      />
      <ImageInput
        fieldId="1"
        state={image}
        setState={setImage}
        allowUpdateImage
      >
        Upload Testimonial Image
      </ImageInput>

      <FormSubmitButton text="Update" />
    </Form>
  );
}

export default UpdateCareerOpenings;
