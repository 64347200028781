import React, { useEffect, useState } from "react";
import { BLOG_IMAGES_API } from "../../../../../../Utilities/APIs";
import Form from "../../../../../Partials/Layouts/Forms/Form";
import ImageInput from "../../../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
import VideoInput from "../../../../../Partials/Layouts/Forms/FormInputs/VideoInput/VideoInput";

function UpdateBlogImage({
  targetID,
  triggerFetch,
  setShowUpdateForm,
  setShowModal,
  BlogId,
}) {
  const [image, setImage] = useState("");
  const [video, setVideo] = useState("");
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetBlogInfo() {
      const { data } = await axiosInstance.get(BLOG_IMAGES_API + targetID);
      setImage(data.image);
      setVideo(data.video);
    }
    fetchAndSetBlogInfo();
  }, [targetID, axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();
    itemData.append("image", image);
    itemData.append("video", video);
    itemData.append("BlogId", targetID);

    const response = await axiosInstance.patch(
      BLOG_IMAGES_API + targetID,
      itemData
    );

    if (response) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ImageInput
        fieldId="1"
        state={image}
        setState={setImage}
        allowUpdateImage
      >
        Upload Card Image
      </ImageInput>

      <VideoInput fieldId="2" state={video} setState={setVideo} />
      {/* <FormSubmitButton text="Update Blog" /> */}
      <FormSubmitButton text="Update" />
    </Form>
  );
}
export default UpdateBlogImage;
