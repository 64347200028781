import React, { useEffect, useState } from "react";
import { Award_API } from "../../../../Utilities/APIs";
import Form from "../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import CustomEditor from "../../../Partials/Elements/CustomEditor/CustomEditor";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";

function UpdateAward({
  targetID,
  triggerFetch,
  setShowUpdateForm,
  setShowModal,
}) {
  const [image, setImage] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [cardDescription, setCardDescription] = useState("");
  const [time, setTime] = useState("");

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetNewsInfo() {
      const { data } = await axiosInstance.get(Award_API + targetID);
      setTitle(data.title);
      setDescription(data?.description);
      setCardDescription(data?.cardDescription);
      setImage(data.image);

      setTime(data.time);
    }
    fetchAndSetNewsInfo();
  }, [targetID, axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();
    itemData.append("image", image);
    itemData.append("description", description);
    itemData.append("cardDescription", cardDescription);
    itemData.append("title", title);
    itemData.append("time", time);

    const response = await axiosInstance.patch(Award_API + targetID, itemData);

    if (response) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Title`}
        value={title}
        placeholder={`Enter Title`}
        setState={setTitle}
      />
      <ShortTextInput
        label={`Card Description`}
        value={cardDescription}
        placeholder={`Enter Card Description`}
        setState={setCardDescription}
      />
      <CustomEditor setState={setDescription} data={description || ""} />
      <ShortTextInput
        label={`Time`}
        value={time}
        placeholder={`Enter Time`}
        setState={setTime}
      />
      <ImageInput
        fieldId="1"
        state={image}
        setState={setImage}
        allowUpdateImage
      >
        Upload Image
      </ImageInput>
      {/* <FormSubmitButton text="Update Award" /> */}
      <FormSubmitButton text="Update" />
    </Form>
  );
}

export default UpdateAward;
