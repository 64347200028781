import React, { useState } from "react";
import { OUr_TEAM_API } from "../../../../Utilities/APIs";
import Form from "../../../Partials/Layouts/Forms/Form";
import ShortTextInput from "../../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import CustomEditor from "../../../Partials/Elements/CustomEditor/CustomEditor";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";

function CreateOurTeam({ setShowCreateForm, setShowModal, triggerFetch }) {
  const [image, setImage] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [designation, setDesignation] = useState("");
  const axiosInstance = useAxiosInstance();

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();

    itemData.append("image", image);
    itemData.append("name", name);
    itemData.append("designation", designation);
    itemData.append("description", description);

    const response = await axiosInstance.post(OUr_TEAM_API, itemData);

    if (response) {
      setShowCreateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ShortTextInput
        label={`Name`}
        value={name}
        placeholder={`Enter Name`}
        setState={setName}
      />

      <ShortTextInput
        label={`Designation`}
        value={designation}
        placeholder={`Enter Designation`}
        setState={setDesignation}
      />

      <CustomEditor setState={setDescription} data={description || ""} />
      <ImageInput
        fieldId="1"
        state={image}
        setState={setImage}
        allowCreateImage
      >
        Upload Our Team Image
      </ImageInput>
      {/* <FormSubmitButton text="Create Our Team" /> */}
      <FormSubmitButton text="Create" />
    </Form>
  );
}

export default CreateOurTeam;
