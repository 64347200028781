import React, { useEffect, useState } from "react";
import { PRODUCTS_ASSET_API } from "../../../Utilities/APIs";
import Form from "../../Partials/Layouts/Forms/Form";
import ImageInput from "../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import ShortTextInput from "../../Partials/Layouts/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import useAxiosInstance from "../../../Utilities/Hooks/useAxiosInstance";

function UpdateProductAsset({
  targetID,
  triggerFetch,
  setShowUpdateForm,
  setShowModal,
  productId,
}) {
  const [image, setImage] = useState("");
  const [precedence, setPrecedence] = useState("");
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetProductInfo() {
      const { data } = await axiosInstance.get(PRODUCTS_ASSET_API + targetID);
      setImage(data.image);
      setPrecedence(data.precedence);
    }
    fetchAndSetProductInfo();
  }, [targetID]);

  async function handleSubmit(e) {
    e.preventDefault();
    const itemData = new FormData();

    itemData.append("product", productId);
    itemData.append("precedence", precedence);
    itemData.append("image", image);

    const response = await axiosInstance.patch(
      PRODUCTS_ASSET_API + targetID,
      itemData
    );

    if (response) {
      setShowUpdateForm(false);
      setShowModal(false);
      triggerFetch();
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <ImageInput
        fieldId="1"
        state={image}
        setState={setImage}
        allowUpdateImage
      >
        Upload product Image
      </ImageInput>

      <ShortTextInput
        label={`Precedence`}
        value={precedence}
        placeholder={`Precedence`}
        setState={setPrecedence}
      />
      <FormSubmitButton text="Create" />
    </Form>
  );
}

export default UpdateProductAsset;
